import styled from '@emotion/styled';
import { useState } from 'react';
import config from '../../../config';

const Div = styled('div')`
    display: flex;
    justify-content: center;
    padding-bottom: 4px;
`;

const Select = styled('select')`
    background: #001934;
    border:none;
    text-align:center;
    color: white ;
    font-weight:700;

    &:focus-visible{
        outline:none
    }
`;

const VersionDropdown = () => {
    const [version, setVersion] = useState(null)

    const versionsList = [
        "4.2.1.10",
        "4.2.1.9",
        "4.2.1.8",
        "4.2.1.7",
        "4.2.1.6",
        "4.2.1.5"    
    ]

    const handleChange = (event) => {
        setVersion(event.target.value)
        document.location.href = `${config.gatsby.docUrl}/docs-${event.target.value}`
    }

    return (
        <Div>
            <Select className='versionDropDown' onChange={handleChange} value={version}>
                {versionsList.map((vers, index) => (<option key={vers} value={vers}>v{vers}</option>))}
            </Select>
        </Div>
    )
}

export default VersionDropdown
