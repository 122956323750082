const config = {
	"gatsby": {
		"pathPrefix": "/docs-4.2.1.10",
		"siteUrl": "https://www.openiam.com",
		"docUrl": "https://docs.openiam.com",
		"gaTrackingId": null,
		"trailingSlash": false,
	},
	"header": {
		"logo": "https://www.openiam.com/wp-content/uploads/logo.png",
		"logoLink": "https://www.openiam.com",
		"title": "Documentation 4.2.1.10",
		"githubUrl": '',
		"helpUrl": "",
		"tweetText": '',
		"social": '',
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": process.env.GATSBY_ALGOLIA_SEARCH_ENABLE,
			"indexName": process.env.GATSBY_ALGOLIA_INDEX_NAME,
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.GATSBY_ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
			"/getting-started",
			"/installation",
			"/admin",
			"/developerguide",
			"/end-user-guide-for-selfservice",
			"/connectorconfig",
			"/ssocatalog",
			"/appendix",
			"/whatsnew",
			"/changelog"
		],
		"links": [
			{ "text": "Openiam", "link": "https://www.openiam.com"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "OpenIAM Documentation v4.2.1.10 | OpenIAM",
		"description": "OpenIAM v4.2.1.10 EE Documentation",
		"ogImage": null,
		"docsLocation": "",
		"favicon": "http://docs.openiam.com/docs4111/html/Skins/Favicons/favicon-96x96.png"
	}
};

module.exports = config;
